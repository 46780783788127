import * as React from "react"
import { graphql } from "gatsby"

import Seo from "../components/Seo"
import TitleSecondary from "../components/TitleSecondary"
import Installations from "../components/Installations"
import ActivitiesOffers from "../components/ActivitiesOffers"
import StudyPlan from "../components/StudyPlan"
import Schedule from "../components/schedule"
import Requirements from "../components/Requirements"

export const pageQuery = graphql`
  query Secondary {
    graphCmsB1PaginadeSecundaria(tituloDeLaPagina: { eq: "Secundaria" }) {
      id
      tituloDeLaPagina
      colorDeLaPagina
      componentenivel1deSecundaria {
        ... on GraphCMS_C1TituloEImagenSecundaria {
          id
          titulo
          descripcion {
            raw
          }
          posicionDelCuadroDeTexto
          imagen {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      componentenivel2deSecundaria {
        ... on GraphCMS_C2Instalaciones {
          id
          imagenDeFondo {
            gatsbyImageData(placeholder: TRACED_SVG)
          }
          listaDeLasInstalaciones
        }
      }
      componentenivel3deSecundaria {
        ... on GraphCMS_C3ExtracurricularesYOfertas {
          id
          actividadesExtracurriculares {
            raw
          }
          listasDeIconosExtracurriculares {
            gatsbyImageData(placeholder: BLURRED)
          }
          oferta {
            raw
          }
          iconoOferta {
            gatsbyImageData(placeholder: BLURRED)
          }
          iconoExtracurricular {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      componentenivel4deSecundaria {
        horarios {
          raw
        }
      }
      componentenivel5deSecundaria {
        gradoAcademicoYRequisitos {
          raw
        }
      }
      componentenivel6deSecundaria {
        planAcademicoDe1Grado {
          raw
        }
        planAcademicoDe2Grado {
          raw
        }
        planAcademicoDe3Grado {
          raw
        }
      }
    }
  }
`

const SecondaryPage = ({ data: { graphCmsB1PaginadeSecundaria } }) => {
  let theme =
    graphCmsB1PaginadeSecundaria.colorDeLaPagina === "Rojo"
      ? "#ff0000"
      : graphCmsB1PaginadeSecundaria.colorDeLaPagina === "Azul"
      ? "#155d7c"
      : graphCmsB1PaginadeSecundaria.colorDeLaPagina === "Cian"
      ? "#70c4a4"
      : graphCmsB1PaginadeSecundaria.colorDeLaPagina === "Verde_Lima"
      ? "#4b7c1c"
      : graphCmsB1PaginadeSecundaria.colorDeLaPagina === "Verde_Obscuro"
      ? "#0a6326"
      : graphCmsB1PaginadeSecundaria.colorDeLaPagina === "Rosa"
      ? "#b30667"
      : graphCmsB1PaginadeSecundaria.colorDeLaPagina === "Morado"
      ? "#4a017d"
      : "#ff0000"

  let colorIcon =
    graphCmsB1PaginadeSecundaria.colorDeLaPagina === "Rojo"
      ? "red"
      : graphCmsB1PaginadeSecundaria.colorDeLaPagina === "Azul"
      ? "blue"
      : graphCmsB1PaginadeSecundaria.colorDeLaPagina === "Cian"
      ? "cian"
      : graphCmsB1PaginadeSecundaria.colorDeLaPagina === "Verde_Lima"
      ? "lightgreen"
      : graphCmsB1PaginadeSecundaria.colorDeLaPagina === "Verde_Obscuro"
      ? "darkgreen"
      : graphCmsB1PaginadeSecundaria.colorDeLaPagina === "Rosa"
      ? "pink"
      : graphCmsB1PaginadeSecundaria.colorDeLaPagina === "Morado"
      ? "purple"
      : "red"

  return (
    <>
      <Seo title={graphCmsB1PaginadeSecundaria.tituloDeLaPagina} />
      <TitleSecondary
        component={graphCmsB1PaginadeSecundaria.componentenivel1deSecundaria}
        theme={theme}
        colorIcon={colorIcon}
      />
      <Installations
        component={graphCmsB1PaginadeSecundaria.componentenivel2deSecundaria}
        theme={theme}
      />
      <ActivitiesOffers
        component={graphCmsB1PaginadeSecundaria.componentenivel3deSecundaria}
        colorIcon={colorIcon}
      />
      <Schedule
        component={graphCmsB1PaginadeSecundaria.componentenivel4deSecundaria}
      />
      <Requirements
        component={graphCmsB1PaginadeSecundaria.componentenivel5deSecundaria}
      />
      <StudyPlan
        component={graphCmsB1PaginadeSecundaria.componentenivel6deSecundaria}
      />
    </>
  )
}

export default SecondaryPage
