import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { RichText } from "@graphcms/rich-text-react-renderer"

const ActivitiesOffers = ({ component, colorIcon }) => (
  <section className="activitiesoffers-section">
    <div className="activitiesoffers-content">
      <div className="activitiesoffers-grid">
        <div>
          <div className="activitiesoffers-box">
            <div className="activitiesoffers-icon-box">
              <div className="activitiesoffers-icon-before" />
              <GatsbyImage
                image={getImage(component.iconoExtracurricular)}
                alt=""
                className={`activitiesoffers-icon icon-${colorIcon}`}
              />
            </div>
            <p className="activitiesoffers-title">Extracurriculares</p>
            <p className="activitiesoffers-text">
              <RichText content={component.actividadesExtracurriculares.raw} />
            </p>
            <div className="activitiesoffers-icons-grid">
              {component.listasDeIconosExtracurriculares.map(lista => (
                <GatsbyImage
                  image={getImage(lista)}
                  alt=""
                  className={`activitiesoffers-icon-list`}
                />
              ))}
            </div>
          </div>
        </div>
        <div>
          <div className="activitiesoffers-box">
            <div className="activitiesoffers-icon-box">
              <div className="activitiesoffers-icon-before" />
              <GatsbyImage
                image={getImage(component.iconoOferta)}
                alt=""
                className={`activitiesoffers-icon icon-${colorIcon}`}
              />
            </div>
            <p className="activitiesoffers-title">Ofrecemos</p>
            <p className="activitiesoffers-text">
              <RichText content={component.oferta.raw} />
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ActivitiesOffers
