import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { RichText } from "@graphcms/rich-text-react-renderer"

const Requirements = ({ component }) => (
  <section className="requirements-section">
    <div className="requirements-content">
      <div className="whocenhies-box-title-grid">
        <div />
        <div className="whocenhies-cube" style={{ backgroundColor: `red` }} />
        <p className="whocenhies-title">Requisitos de Ingreso</p>
        <div className="whocenhies-cube" style={{ backgroundColor: `red` }} />
        <div />
      </div>
      <div className="requirements-grid">
        {component.gradoAcademicoYRequisitos.map(grado => (
          <div className="requirements-box">
            <div className="studyplan-justify">
              <StaticImage
                placeholder="blurred"
                src="../images/Grado.png"
                alt=""
                className={`studyplan-icon icon-red`}
              />
              <p className="requirements-text">
                <RichText content={grado.raw} />
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
)

export default Requirements
