import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { RichText } from "@graphcms/rich-text-react-renderer"

const Schedule = ({ component }) => (
  <section className="schedule-section">
    <div className="schedule-content">
      <div className="schedule-grid">
        <div className="studyplan-icon-box">
          <div className="studyplan-icon-before" />
          <div className="studyplan-justify">
            <StaticImage
              placeholder="blurred"
              src="../images/Horario.png"
              alt=""
              className={`studyplan-icon icon-red`}
            />
          </div>
        </div>
        <div>
          <div className="workenvironment-box-title-grid">
            <div />
            <div
              className="workenvironment-cube"
              style={{ backgroundColor: `red` }}
            />
            <p className="workenvironment-title">Horarios</p>
            <div
              className="workenvironment-cube"
              style={{ backgroundColor: `red` }}
            />
            <div />
          </div>
          <p className="schedule-text">
            <RichText content={component.horarios.raw} />
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default Schedule
