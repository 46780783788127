import React, { useState } from "react"
import { RichText } from "@graphcms/rich-text-react-renderer"
import { StaticImage } from "gatsby-plugin-image"

const StudyPlan = ({ component }) => {
  const [isOpen1, setIsOpen1] = useState(false)
  const [isOpen2, setIsOpen2] = useState(false)
  const [isOpen3, setIsOpen3] = useState(false)

  const SetOpen1 = () => {
    setIsOpen1(!isOpen1)
  }
  const SetOpen2 = () => {
    setIsOpen2(!isOpen2)
  }
  const SetOpen3 = () => {
    setIsOpen3(!isOpen3)
  }

  return (
    <section className="studyplan-section">
      <div className="studyplan-content">
        <div className="studyplan-line" />
        <div className="whocenhies-box-title-grid">
          <div />
          <div className="whocenhies-cube" style={{ backgroundColor: `red` }} />
          <p className="whocenhies-title">Plan de Estudios</p>
          <div className="whocenhies-cube" style={{ backgroundColor: `red` }} />
          <div />
        </div>
        <div className="studyplan-grid">
          <div>
            <div className="studyplan-icon-box">
              <div className="studyplan-icon-before" />
              <div className="studyplan-justify">
                <StaticImage
                  placeholder="blurred"
                  src="../images/boleta.png"
                  alt=""
                  className={`studyplan-icon icon-red`}
                />
                <p className="studyplan-text">Primer Grado</p>
                <button onClick={() => SetOpen1()} className="studyplan-button">
                  Ver más
                </button>
              </div>
            </div>
            {isOpen1 ? (
              <>
                <button
                  onClick={() => SetOpen1()}
                  className="studyplan-box-modal"
                >
                  {" "}
                </button>
                <div className="studyplan-box-popup">
                  <div className="studyplan-box">
                    <button
                      onClick={() => SetOpen1()}
                      className="studyplan-close"
                    >
                      {" "}
                    </button>
                    <RichText content={component.planAcademicoDe1Grado.raw} />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <div>
            <div className="studyplan-icon-box">
              <div className="studyplan-icon-before" />
              <div className="studyplan-justify">
                <StaticImage
                  placeholder="blurred"
                  src="../images/boleta.png"
                  alt=""
                  className={`studyplan-icon icon-red`}
                />
                <p className="studyplan-text">Segundo Grado</p>
                <button onClick={() => SetOpen2()} className="studyplan-button">
                  Ver más
                </button>
              </div>
            </div>
            {isOpen2 ? (
              <>
                <button
                  onClick={() => SetOpen2()}
                  className="studyplan-box-modal"
                >
                  {" "}
                </button>
                <div className="studyplan-box-popup">
                  <div className="studyplan-box">
                    <button
                      onClick={() => SetOpen2()}
                      className="studyplan-close"
                    >
                      {" "}
                    </button>
                    <RichText content={component.planAcademicoDe2Grado.raw} />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <div>
            <div className="studyplan-icon-box">
              <div className="studyplan-icon-before" />
              <div className="studyplan-justify">
                <StaticImage
                  placeholder="blurred"
                  src="../images/boleta.png"
                  alt=""
                  className={`studyplan-icon icon-red`}
                />
                <p className="studyplan-text">Tercer Grado</p>
                <button onClick={() => SetOpen3()} className="studyplan-button">
                  Ver más
                </button>
              </div>
            </div>
            {isOpen3 ? (
              <>
                <button
                  onClick={() => SetOpen3()}
                  className="studyplan-box-modal"
                >
                  {" "}
                </button>
                <div className="studyplan-box-popup">
                  <div className="studyplan-box">
                    <button
                      onClick={() => SetOpen3()}
                      className="studyplan-close"
                    >
                      {" "}
                    </button>
                    <RichText content={component.planAcademicoDe3Grado.raw} />
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default StudyPlan
