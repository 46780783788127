import { GatsbyImage, getImage } from "gatsby-plugin-image"
import * as React from "react"

const Installations = ({ theme, component }) => (
  <section className="installations-section">
    <GatsbyImage
      image={getImage(component.imagenDeFondo)}
      alt=""
      style={{ borderColor: `${theme}` }}
      className="installations-image"
    />
    <div className="installations-box">
      <div className="installations-box-title-grid">
        <div />
        <div
          className="installations-cube"
          style={{ backgroundColor: `${theme}` }}
        />
        <p className="installations-title">Instalaciones</p>
        <div
          className="installations-cube"
          style={{ backgroundColor: `${theme}` }}
        />
        <div />
      </div>
      <ul>
        {component.listaDeLasInstalaciones.map(lista => (
          <li className="installations-text">{lista}</li>
        ))}
      </ul>
    </div>
  </section>
)

export default Installations
